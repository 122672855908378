
import { Vue, Options } from "vue-class-component";
import ProgressBar from "./components/progress-bar.vue";
import List from "./components/progress-list/list.vue";
import TermCondition from "./components/progress-list/term-condition.vue";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { ProgressiveCommissionDashboardController } from "@/app/ui/controllers/ProgressiveCommissionDashboardController";
import { ProgressiveCommissionConfigurationController } from "@/app/ui/controllers/ProgressiveCommissionConfigurationController";
import { BonusKomisiDetailRequest } from "@/data/payload/api/ProgressiveCommissionDashboardRequest";
import { ProgressiveCommissionListRequest } from "@/data/payload/api/ProgressiveCommissionConfigurationApiRequest";
import { ProgressiveCommissionListTier } from "@/domain/entities/ProgressiveCommissionConfiguration";

@Options({
  components: {
    ProgressBar,
    DetailLayout,
    List,
    TermCondition
  }
})
export default class ProgressiveCommissionDashboard extends Vue {
  goBack() {
    history.back();
  }

  created() {
    this.getDetail();
    this.getConfigurationList();
  }

  page = 1;
  limit = 1;
  getConfigurationList() {
    return ProgressiveCommissionConfigurationController.getProgressiveCommissionList(
      new ProgressiveCommissionListRequest({
        status: "ACTIVE",
        page: this.page,
        limit: this.limit,
        withCpcDetail: true
      })
    );
  }

  get steppers() {
    return ProgressiveCommissionConfigurationController.progressiveCommissionList.data[0]?.cpcDetail.map(
      (item: ProgressiveCommissionListTier) => {
        return {
          limit: item.cpcdMilestoneLimit,
          bonus: item.cpcdCommissionAdditional
        };
      }
    );
  }

  get isLoadingList() {
    return ProgressiveCommissionConfigurationController.isLoading;
  }

  get isErrorList() {
    return ProgressiveCommissionConfigurationController.isError;
  }

  get errorCauseList() {
    return ProgressiveCommissionConfigurationController.errorCause;
  }

  getDetail() {
    return ProgressiveCommissionDashboardController.getBonusKomisiDetail(
      new BonusKomisiDetailRequest({
        startDate: this.$moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: this.$moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      })
    );
  }

  get currentProgress() {
    return ProgressiveCommissionDashboardController.detailBonusKomisi
      .pcrCommissionAdditionalTier;
  }

  get currentTotal() {
    return ProgressiveCommissionDashboardController.detailBonusKomisi
      .pcrTotalCommissionAdditional;
  }

  get isLoadingDetail() {
    return ProgressiveCommissionDashboardController.isLoading;
  }

  get isErrorDetail() {
    return ProgressiveCommissionDashboardController.error;
  }

  get errorCauseDetail() {
    return ProgressiveCommissionDashboardController.errorCause;
  }

  tabs = [
    {
      name: "bonus-komisi",
      title: "Bonus Komisi"
    },
    {
      name: "syarat-ketentuan",
      title: "Syarat & Ketentuan"
    }
  ];

  tabActive = "bonus-komisi";
  changeTabs(value: string) {
    this.tabActive = value;
  }

  isTabActive(name: string) {
    return name === this.tabActive;
  }

  get termCondition() {
    return `${ProgressiveCommissionConfigurationController.progressiveCommissionList.data[0]?.cpcTermCondition}`;
  }
}
