
import { Vue, prop, Options } from "vue-class-component";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import debounce from "lodash/debounce";

class Props {
  steps = prop<any[]>({
    type: Array,
    default: () => []
  });
  progress = prop<any[]>({
    type: Array,
    default: () => []
  });
  currentTotal = prop<number>({
    type: Number,
    default: 0
  });
}

@Options({})
export default class ProgressBar extends Vue.with(Props) {
  formatPrice(val: number) {
    return formatPrice(val);
  }

  container: any = null;
  mounted() {
    this.container = this.$refs.horizontalScrollContainer;
  }

  debounceScroll = debounce((percent: number) => {
    this.container?.scroll({
      left:
        (this.container.scrollWidth - this.container.offsetWidth) * percent +
        (600 * percent - 310),
      behavior: "smooth"
    });
  }, 300);
  checkProgressAndScroll(index: number, total: number, progress: number) {
    if (progress) {
      const percent = index / (total - 1);
      this.debounceScroll(percent);
    }
    return progress;
  }

  get countDown() {
    const dateToday = new Date();
    const lastDayOfMonth = new Date(
      dateToday.getFullYear(),
      dateToday.getMonth() + 1,
      0
    ).getDate();
    const daysUntilEndOfMonth = lastDayOfMonth - dateToday.getDate();
    return daysUntilEndOfMonth;
  }
}
