import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { DetailBonusKomisi } from "@/domain/entities/ProgressiveCommissionDashboard";
import { BonusKomisiDetailRequest } from "@/data/payload/api/ProgressiveCommissionDashboardRequest";
import { ProgressiveCommissionDashboardPresenter } from "../presenters/ProgressiveCommissionDashboardPresenter";
import { container } from "tsyringe";

export interface ProgressiveCommissionDashboardState {
  isLoading: boolean;
  error: boolean;
  errorCause: string;
  detailBonusKomisi: DetailBonusKomisi;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "progressive-commission-dashboard"
})
class ProgressiveCommissionDashboardStore extends VuexModule
  implements ProgressiveCommissionDashboardState {
  public isLoading = false;
  public error = false;
  public errorCause = "";
  public detailBonusKomisi = new DetailBonusKomisi();

  @Action
  public async getBonusKomisiDetail(params: BonusKomisiDetailRequest) {
    this.setLoading(true);
    const presenter = container.resolve(
      ProgressiveCommissionDashboardPresenter
    );
    return presenter
      .getBonusKomisiDetail(params)
      .then((res: DetailBonusKomisi) => {
        this.setDetailBonusKomisi(res);
        this.setErrorCause("");
        this.setError(false);
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setError(error: boolean) {
    this.error = error;
  }

  @Mutation
  public setErrorCause(cause: string) {
    this.errorCause = cause;
  }

  @Mutation
  public setDetailBonusKomisi(data: DetailBonusKomisi) {
    this.detailBonusKomisi = data;
  }
}

export const ProgressiveCommissionDashboardController = getModule(
  ProgressiveCommissionDashboardStore
);
