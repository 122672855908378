
import { Vue, prop, Options } from "vue-class-component";

class Props {
  content = prop<string>({
    type: String,
    default: ""
  });
}

@Options({})
export default class TermCondition extends Vue.with(Props) {}
