import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex justify-between border-b border-gray-lp-400" }
const _hoisted_2 = { class: "flex gap-x-6 text-20px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_List = _resolveComponent("List")!
  const _component_TermCondition = _resolveComponent("TermCondition")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    scrollSidebar: "",
    customClass: "px-0",
    class: "w-full",
    onBack: _ctx.goBack,
    "full-page": "",
    "vertical-separator": false,
    title: "Detail Bonus Komisi",
    loading: _ctx.isLoadingList || _ctx.isLoadingDetail,
    error: _ctx.isErrorList || _ctx.isErrorDetail,
    errorType: _ctx.errorCauseList || _ctx.errorCauseDetail,
    onTryAgain: _ctx.getConfigurationList || _ctx.getDetail
  }, {
    data: _withCtx(() => [
      _createVNode(_component_ProgressBar, {
        steps: _ctx.steppers,
        progress: _ctx.currentProgress,
        currentTotal: _ctx.currentTotal,
        class: "mb-10"
      }, null, 8, ["steps", "progress", "currentTotal"]),
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createBlock("button", {
              key: tab.name,
              onClick: ($event: any) => (_ctx.changeTabs(tab.name)),
              class: 
              `pb-4 font-semibold hover:text-red-lp-100 focus:outline-none ${
                _ctx.isTabActive(tab.name)
                  ? 'text-red-lp-100 border-b-2 border-red-lp-100'
                  : 'text-gray-lp-600'
              }`
            
            }, _toDisplayString(tab.title), 11, ["onClick"]))
          }), 128))
        ])
      ]),
      _withDirectives(_createVNode(_component_List, {
        steps: _ctx.steppers,
        progress: _ctx.currentProgress
      }, null, 8, ["steps", "progress"]), [
        [_vShow, _ctx.tabActive === 'bonus-komisi']
      ]),
      _withDirectives(_createVNode(_component_TermCondition, { content: _ctx.termCondition }, null, 8, ["content"]), [
        [_vShow, _ctx.tabActive === 'syarat-ketentuan']
      ])
    ]),
    _: 1
  }, 8, ["onBack", "loading", "error", "errorType", "onTryAgain"]))
}