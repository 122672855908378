
import { Vue, prop, Options } from "vue-class-component";
import { formatPrice } from "@/app/infrastructures/misc/Utils";

class Props {
  steps = prop<any[]>({
    type: Array,
    default: () => []
  });
  progress = prop<any[]>({
    type: Array,
    default: () => []
  });
}

@Options({})
export default class List extends Vue.with(Props) {
  formatPrice(val: number) {
    return formatPrice(val);
  }
}
